import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import dateFormat from "dateformat"
import Layout from "../../components/layout"
import Markdown from "react-markdown"
import Footer from "../../components/footer"

export const query = graphql`
  query ArticleQuery($slug: String!) {
    strapiArticles(slug: { eq: $slug }) {
      strapiId
      title
      description
      content
      published_at
      image {
        localFile {
          publicURL
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: TRACED_SVG)
          }
        }
      }
      author {
        name
      }
      read_time
      categories {
        title
      }
    }
  }
`

const Article = ({ data }) => {
  const article = data.strapiArticles
  const seo = {
    metaTitle: article.title,
    metaDescription: article.description,
    shareImage: article.image,
    article: true,
  }
  console.log(article)
  return (
    <Layout seo={seo}>
      <div className="lg:mx-14 px-4 sm:px-6">
        <div className="flex flex-col gap-12 py-12">
          <div className="py-12">
            <p className="text-7xl font-semibold">{article.title}</p>
          </div>
          <div className="inline-block lg:flex lg:flex-row gap-16">
            <div className="lg:w-1/3">
              <div className="flex flex-col gap-6">
                <div className="border-t flex flex-row justify-between border-black rounded-l-full text-lg">
                  <div className="border-r border-l border-b rounded-full px-6 py-1 border-black">
                    <p>Category</p>
                  </div>
                  <p>{article.categories[0].title}</p>
                </div>
                <div className="border-t flex flex-row justify-between border-black rounded-l-full text-lg">
                  <div className="border-r border-l border-b rounded-full px-6 py-1 border-black">
                    <p>Author</p>
                  </div>
                  <p>{article.author.name}</p>
                </div>
                <div className="border-t flex flex-row justify-between border-black rounded-l-full text-lg">
                  <div className="border-r border-l border-b rounded-full px-6 py-1 border-black">
                    <p>Date</p>
                  </div>
                  {dateFormat(article.published_at, "mmmm dS, yyyy")}
                </div>
                <div className="border-t flex flex-row justify-between border-black rounded-l-full text-lg">
                  <div className="border-r border-l border-b rounded-full px-6 py-1 border-black">
                    <p>Read Time</p>
                  </div>
                  <p>{article.read_time}</p>
                </div>
              </div>
            </div>
            <div className="lg:w-2/3">
              <GatsbyImage
                alt={`Picture for ${article.title} article`}
                image={article.image.localFile.childImageSharp.gatsbyImageData}
                className="rounded-xl custom_img"
              />
            </div>
          </div>
          <div className="blog_post">
            <Markdown>{article.content}</Markdown>
          </div>

          <hr className="border border-black" />
          <Footer />
        </div>
      </div>
    </Layout>
  )
}

export default Article
